import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/seed/source/services/main-www/src/components/mdx-docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// cards2cards.js
const fetch = require('node-fetch')
const crypto = require('crypto')
const queryString = require('query-string')

async function handleErrors(response) {
  if (!response.ok) {
    let json
    try {
      json = await response.json()
    } catch(e) {
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }
    const error = new Error(json.error)
    error.response = response
    error.json = json
    throw error
  }
  return response
}

class Cards2cards {
  constructor(apiKey, apiSecret, baseUrl = 'https://api.cards2cards.com') {
    this.apiKey = apiKey
    this.apiSecret = apiSecret
    this.baseUrl = baseUrl
    this.errors = require('./cards2cards-errors')
    this.webhooks = require('./cards2cards-webhooks')
  }

  accessToken(method, path, querySorted, body) {
    //get unix time in seconds
    const timestamp = Math.floor(Date.now() / 1000)

    const version = '2019-12-22'

    const message = timestamp + method.toUpperCase() + path + querySorted + (body || '')

    //create a hexedecimal encoded SHA256 signature of the message
    const signature = crypto.createHmac('sha256', this.apiSecret).update(message).digest('hex')

    return [version, timestamp, this.apiKey, signature].join('.')
  }

  async api(path, {method = 'GET', parameters} = {}) {
    const params = { ...parameters }

    const queryInsteadOfBody = method === 'GET' || method === 'HEAD'
    let query = ''
    let body = undefined
    if (queryInsteadOfBody) {
      // Stringify an object into a query string and sorting the keys.
      const str = queryString.stringify(params)
      if (str) {
        query = \`?\${str}\`
      }
    } else {
      body = JSON.stringify(params)
    }

    const accessToken = this.accessToken(method, path, query, body)

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': \`NP-ACCESS-TOKEN \${accessToken}\`,
    }

    const response = await fetch(this.baseUrl + path + query, {
      method,
      headers,
      body,
    })
    const res = await handleErrors(response)
    return await res.json()
  }
}

Cards2cards.errors = require('./cards2cards-errors')
Cards2cards.webhooks = require('./cards2cards-webhooks')

module.exports = Cards2cards
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// cards2cards-errors.js

/**
 * Cards2cardsError is the base error from which all other more specific Cards2cards errors derive.
 * Specifically for errors returned from Cards2cards's REST API.
 */
class Cards2cardsError extends Error {
  constructor(raw = {}) {
    super(raw.message)
    this.type = this.constructor.name

    this.raw = raw
    this.detail = raw.detail
    this.message = raw.message
  }
}

/**
 * SignatureVerificationError is raised when the signature verification for a
 * webhook fails
 */
class Cards2cardsSignatureVerificationError extends Cards2cardsError {}

module.exports.Cards2cardsError = Cards2cardsError
module.exports.Cards2cardsSignatureVerificationError = Cards2cardsSignatureVerificationError
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// cards2cards-webhooks.js
const crypto = require('crypto')

const {Cards2cardsError, Cards2cardsSignatureVerificationError} = require('./cards2cards-errors')

const Webhook = {
  DEFAULT_TOLERANCE: 300, // 5 minutes

  constructEvent(payload, header, secret, tolerance) {
    this.signature.verifyHeader(
      payload,
      header,
      secret,
      tolerance || Webhook.DEFAULT_TOLERANCE
    )

    const jsonPayload = JSON.parse(payload)
    return jsonPayload
  },

  /**
   * Generates a header to be used for webhook mocking
   *
   * @typedef {object} opts
   * @property {number} timestamp - Timestamp of the header. Defaults to Date.now()
   * @property {string} payload - JSON stringified payload object, containing the 'id' and 'object' parameters
   * @property {string} secret - Cards2cards webhook secret 'whsec_...'
   * @property {string} scheme - Version of API to hit. Defaults to 'v1'.
   * @property {string} signature - Computed webhook signature
   */
  generateTestHeaderString: function(opts) {
    if (!opts) {
      throw new Cards2cardsError({
        message: 'Options are required',
      })
    }

    opts.timestamp =
      Math.floor(opts.timestamp) || Math.floor(Date.now() / 1000)
    opts.scheme = opts.scheme || signature.EXPECTED_SCHEME

    opts.signature =
      opts.signature ||
      signature._computeSignature(
        opts.timestamp + '.' + opts.payload,
        opts.secret
      )

    const generatedHeader = [
      't=' + opts.timestamp,
      opts.scheme + '=' + opts.signature,
    ].join(',')

    return generatedHeader
  },
}

const signature = {
  EXPECTED_SCHEME: 'v1',

  _computeSignature: (payload, secret) => {
    return crypto
      .createHmac('sha256', secret)
      .update(payload, 'utf8')
      .digest('hex')
  },

  verifyHeader(payload, header, secret, tolerance) {
    payload = Buffer.isBuffer(payload) ? payload.toString('utf8') : payload

    // Express's type for \`Request#headers\` is \`string | []string\`
    // which is because the \`set-cookie\` header is an array,
    // but no other headers are an array (docs: https://nodejs.org/api/http.html#http_message_headers)
    // (Express's Request class is an extension of http.IncomingMessage, and doesn't appear to be relevantly modified: https://github.com/expressjs/express/blob/master/lib/request.js#L31)
    if (Array.isArray(header)) {
      throw new Error(
        'Unexpected: An array was passed as a header, which should not be possible for the Np-Signature header.'
      )
    }

    header = Buffer.isBuffer(header) ? header.toString('utf8') : header

    const details = parseHeader(header, this.EXPECTED_SCHEME)

    if (!details || details.timestamp === -1) {
      throw new Cards2cardsSignatureVerificationError({
        message: 'Unable to extract timestamp and signatures from header',
        detail: {
          header,
          payload,
        },
      })
    }

    if (!details.signatures.length) {
      throw new Cards2cardsSignatureVerificationError({
        message: 'No signatures found with expected scheme',
        detail: {
          header,
          payload,
        },
      })
    }

    const expectedSignature = this._computeSignature(
      \`\${details.timestamp}.\${payload}\`,
      secret
    )

    const signatureFound = !!details.signatures.filter(
      secureCompare.bind(null, expectedSignature)
    ).length

    if (!signatureFound) {
      throw new Cards2cardsSignatureVerificationError({
        message:
          'No signatures found matching the expected signature for payload.' +
          ' Are you passing the raw request body you received from Cards2cards?',
        detail: {
          header,
          payload,
        },
      })
    }

    const timestampAge = Math.floor(Date.now() / 1000) - details.timestamp

    if (tolerance > 0 && timestampAge > tolerance) {
      throw new Cards2cardsSignatureVerificationError({
        message: 'Timestamp outside the tolerance zone',
        detail: {
          header,
          payload,
        },
      })
    }

    return true
  },
}

function parseHeader(header, scheme) {
  if (typeof header !== 'string') {
    return null
  }

  return header.split(',').reduce(
    (accum, item) => {
      const kv = item.split('=')

      if (kv[0] === 't') {
        accum.timestamp = kv[1]
      }

      if (kv[0] === scheme) {
        accum.signatures.push(kv[1])
      }

      return accum
    },
    {
      timestamp: -1,
      signatures: [],
    }
  )
}

Webhook.signature = signature

/**
 * Secure compare, from https://github.com/freewil/scmp
 */
const secureCompare = (a, b) => {
  a = Buffer.from(a)
  b = Buffer.from(b)

  // return early here if buffer lengths are not equal since timingSafeEqual
  // will throw if buffer lengths are not equal
  if (a.length !== b.length) {
    return false
  }

  // use crypto.timingSafeEqual if available (since Node.js v6.6.0),
  // otherwise use our own scmp-internal function.
  if (crypto.timingSafeEqual) {
    return crypto.timingSafeEqual(a, b)
  }

  const len = a.length
  let result = 0

  for (let i = 0; i < len; ++i) {
    result |= a[i] ^ b[i]
  }
  return result === 0
}

module.exports = Webhook
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      